import React from "react"
import { Link } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import jpg_milford_sound_day_tour_te_anau from "../../../../images/sliders/milford-sound-day-tour-te-anau.jpg"
import jpg_mfsd_cruise_passengers from "../../../../images/tours/awesomenz-milford-sound-cruise-passengers.jpg"
import jpg_mfsd_sundowner_cruise from "../../../../images/tours/awesomenz-milford-sound-sundowner-cruise.jpg"
import jpg_mfsd_te_anau from "../../../../images/thumbs/milford_ex_te_anau.jpg"
import jpg_mfsd_sundowner_cruise_te_anau from "../../../../images/tours/awesomenz-milford-sound-sundowner-cruise-te-anau.jpg"
import jpg_mfsd_chc_zqn from "../../../../images/thumbs/milford_chc_zqn.jpg"
import jpg_mfsd_chc_mt_cook from "../../../../images/thumbs/milford_chc_mt_cook.jpg"

function MilfordSoundIndex(props) {
  return (
    <>
      <SEO title="Milford Sound Tours & Cruises | Milford Sound Day Trips - awesomeNZ" />
      <Layout
        layoutType="typography TourHolderPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={jpg_milford_sound_day_tour_te_anau}
                alt=""
                class="first image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={false}
        hasSliderBlurSmall={true}
        content={
          <>
            <div id="TourHolderPage" class="clearfix">
              <div class="title-div">
                <h1>Milford Sound</h1>
                <h3>
                  Discover this remote and beautiful fiord with a day tour and
                  cruise
                </h3>
                <Link
                  class="alltours"
                  to="/home/new-zealand-tours/milford-sound-tours#tours-section"
                >
                  <p>View all Milford Sound tours &gt;</p>
                </Link>
              </div>
              <div class="destination-content">
                <p> </p>
                <p>
                  The pristine{" "}
                  <strong>
                    <a
                      href="https://www.milford-sound.co.nz"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Milford Sound
                    </a>
                  </strong>{" "}
                  is part of the{" "}
                  <strong>World Heritage Fiordland National Park</strong>, in
                  the south west of the South Island, and is famed for its
                  remote atmosphere and rugged beauty. It is New Zealand’s best
                  known fiord and the only one that can be reached by road.
                  Maori are believed to have discovered Milford Sound more than
                  1,000 years ago, returning seasonally to collect the prized{" "}
                  <em>pounamu</em> (greenstone). In the early 1800s John Grono
                  was the first European settler to land in the sound, which he
                  named after Milford Haven in Wales.{" "}
                </p>
                <p>
                  Described by Rudyard Kipling as the 'eighth wonder of the
                  world', Milford Sound was carved by glaciers during the ice
                  ages. Milford Sound is breathtaking in any weather. Cliffs
                  rise high into the air from the dark waters, mountain peaks
                  scrape the sky and waterfalls cascade downwards, some as high
                  as 1000 metres. When it rains in Milford Sound (and it often
                  does) those waterfalls multiply with magnificent effect.{" "}
                </p>
                <p>
                  Take a <strong>day trip to Milford Sound</strong> with
                  awesomeNZ and experience one of New Zealand's must-do
                  attractions. Sightseeing tours to Milford Sound depart daily
                  from Queenstown and Te Anau year round.{" "}
                </p>
              </div>
              <div id="tours-section" class="row">
                <div class="tour-item six columns tag-tour first-tour">
                  <h3>Our most popular tour</h3>
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/milford-sound-tours/milford-sound-day-tour-from-queenstown"
                      class="tour-pic"
                    >
                      <img
                        src={jpg_mfsd_cruise_passengers}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2> Milford Sound Day Tour</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>
                      <span class="tour-details-prices-price">$189.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-day-tour-from-queenstown">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>
                <div class="tour-item six columns tag-tour">
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/milford-sound-tours/milford-sound-day-tour-from-queenstown"
                      class="tour-pic"
                    >
                      <img
                        src={jpg_mfsd_cruise_passengers}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2> Milford Sound Day Tour</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>
                      <span class="tour-details-prices-price">$189.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-day-tour-from-queenstown">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>
                <div class="tour-item six columns tag-tour">
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/milford-sound-tours/sundowner-tour-from-queenstown"
                      class="tour-pic"
                    >
                      <img
                        src={jpg_mfsd_sundowner_cruise}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2>Milford Sound Sundowner Tour from Queenstown</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>
                      <span class="tour-details-prices-price">$139.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/sundowner-tour-from-queenstown">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>
                <div class="tour-item six columns tag-tour">
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-from-te-anau"
                      class="tour-pic"
                    >
                      <img
                        src={jpg_mfsd_te_anau}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2>Milford Sound Day Trip from Te Anau</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>
                      <span class="tour-details-prices-price">$165.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-from-te-anau">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>
                <div class="tour-item six columns tag-tour">
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-christchurch-queenstown"
                      class="tour-pic"
                    >
                      <img
                        src={jpg_mfsd_chc_zqn}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2>
                      Milford Sound Tour + Christchurch - Queenstown travel
                    </h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$199.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-christchurch-queenstown">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>
                <div class="tour-item six columns tag-tour">
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/milford-sound-tours/sundowner-tour-from-te-anau"
                      class="tour-pic"
                    >
                      <img
                        src={jpg_mfsd_sundowner_cruise_te_anau}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2>Milford Sound Sundowner Tour from Te Anau</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$129.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/sundowner-tour-from-te-anau">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>
                <div class="tour-item six columns tag-tour">
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-mt-cook-tour"
                      class="tour-pic"
                    >
                      <img
                        src={jpg_mfsd_chc_mt_cook}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2>Milford Sound Tour + Mt Cook Tour Combo</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$285.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-mt-cook-tour">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default MilfordSoundIndex
